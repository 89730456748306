.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-page {
  background-image: url('./img/hotel.png');
  background-size: cover;
  background-position: center;
  height: 50vh; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3498db; /* Green background */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.navbar button {
  background-color: #ff4757; /* Red button */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navbar button:hover {
  background-color: #e84118;
}

.navbar .user-info {
  color: white;
}

.navbar .user-info h5 {
  display: inline;
  margin: 0;
  font-weight: normal;
}





